export const defaultParams = (method) => ({
  baseURL: useNuxtApp().$config.public.apiBase,
  method: method.toUpperCase(),
});

function onError(e) {
  if (e.data && typeof e.data === 'string') {
    if (e.data.length > 0) {
      // useToastError(e.data)
      alert(e.data);
    }
  }
  return Promise.reject(e);
}

export function useGet(endpoint, params = {}) {
  // console.log('useGet', endpoint, defaultParams('GET'));
  return $fetch(endpoint, { ...defaultParams('GET'), params });
}

export function usePost(endpoint, params = {}) {
  try {
    return $fetch(endpoint, { ...defaultParams('POST'), body: params });
  } catch (e) {
    return onError(e);
  }
}

export function usePut(endpoint, params = {}) {
  try {
    return $fetch(endpoint, { ...defaultParams('PUT'), body: params });
  } catch (e) {
    return onError(e);
  }
}

export function usePatch(endpoint, params = {}) {
  try {
    return $fetch(endpoint, { ...defaultParams('PATCH'), body: params });
  } catch (e) {
    return onError(e);
  }
}

export function useDelete(endpoint, params = {}) {
  try {
    return $fetch(endpoint, { ...defaultParams('DELETE'), params });
  } catch (e) {
    return onError(e);
  }
}

export function useApi(method, endpoint, params) {
  switch (method.toLowerCase()) {
    case 'get':
      return useGet(endpoint, params);
    case 'post':
      return usePost(endpoint, params);
    case 'put':
      return usePut(endpoint, params);
    case 'patch':
      return usePatch(endpoint, params);
    case 'delete':
      return useDelete(endpoint, params);
    default:
      return useGet(endpoint, params);
  }
}
