export const useFetchFromCurrenciesIds = () => useGet('/api/v1/calculator/from/');

export const useFetchToCurrenciesIds = fromId => useGet(`/api/v1/calculator/from/${fromId}/`);

export const useFetchExchangeRate = (fromId, toId) => useGet(`/api/v1/calculator/pair/${fromId}/${toId}/`);

export const useFetchReserves = () => useGet('/api/v1/calculator/reserves/');

export const useFetchSettings = (
    from = '',
    to = '',
) => useGet(`/api/v1/calculator/settings/?cur_from=${from}&cur_to=${to}`);
