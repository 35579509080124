import { defineStore } from 'pinia';
import { useFetchExchangeRate } from '~/composables/useDirectionsApi';
import { useCurrenciesStore } from '~/stores/currencies';

export const usePairDataStore = defineStore('pairData', () => {
  const pairData = ref({});
  const currenciesStore = useCurrenciesStore();
  const refferal = ref(null);

  const fromRequisites = ref({});
  const toRequisites = ref({});

  const fromOptions = ref([]);
  const toOptions = ref([]);

  const fromValue = ref(0);

  const isEmpty = computed(
    () =>
      Object.keys(fromRequisites.value).length === 0 ||
      Object.keys(toRequisites.value).length === 0 ||
      fromOptions.value.length === 0 ||
      toOptions.value.length === 0 ||
      fromValue.value === 0
  );

  function setFromValue(value) {
    fromValue.value = value;
  }

  function setRefferal(data) {
    refferal.value = data;
  }

  function setPairData(data) {
    pairData.value = data;
  }

  function populateFromOptions() {
    // Get options from pair data
    const options = getOptionsFromPairData(pairData.value, 'from');
    // Map options to correct format
    fromOptions.value = options.map((option) => ({
      value: option.id,
      label: option.currency || option.network,
      active: option.active,
    }));
  }

  function populateToOptions() {
    // Get options from pair data
    const options = getOptionsFromPairData(pairData.value, 'to');
    // Map options to correct format
    toOptions.value = options.map((option) => ({
      value: option.id,
      label: option.currency || option.network,
      active: option.active,
    }));
  }

  function populateFromInputs() {
    fromRequisites.value = populateFormInputsObject(
      fromRequisites.value,
      pairData.value.structure.from.input,
      'send'
    );
  }

  function populateToInputs() {
    toRequisites.value = populateFormInputsObject(
      toRequisites.value,
      pairData.value.structure.to.input,
      'receive'
    );
  }

  async function updatePairData() {
    try {
      const res = await useFetchExchangeRate(
        currenciesStore.currenciesIds.from,
        currenciesStore.currenciesIds.to
      );
      setPairData(res || {});
    } catch (e) {
      console.error(e);
    }
  }

  function updateValues() {
    populateFromInputs();
    populateToInputs();
    populateFromOptions();
    populateToOptions();
    fromValue.value = minValue.value;
  }

  const formRequisites = computed(() => ({
    fromRequisites: fromRequisites.value,
    toRequisites: toRequisites.value,
  }));

  const minValue = computed(() => {
    const fromMin = pairData.value?.from?.min;
    const toMin = pairData.value?.to?.min;
    const course = pairData.value?.course;
    if (fromMin) {
      return fromMin;
    }
    if (toMin && course) {
      return toMin / course;
    }
    return undefined;
  });

  const maxValue = computed(() => {
    const fromMax = pairData.value?.from?.max;
    if (fromMax) {
      return fromMax;
    }
    return undefined;
  });

  const course = computed(() => {
    if (pairData.value) {
      return pairData.value.course;
    }
    return 0;
  });

  const fromCurrency = computed(() => pairData.value?.from?.currency ?? '');

  const toCurrency = computed(() => pairData.value?.to?.currency ?? '');

  const decimals = computed(() => {
    if (pairData.value) {
      const { from, to } = pairData.value;
      if (from && to) {
        return {
          from: from.roundCalculator,
          to: to.roundCalculator,
        };
      }
    }
    return {
      from: 0,
      to: 0,
    };
  });

  return {
    pairData,
    updatePairData,
    updateValues,
    fromRequisites,
    toRequisites,
    formRequisites,
    fromOptions,
    toOptions,
    refferal,
    setRefferal,
    minValue,
    maxValue,
    course,
    fromCurrency,
    toCurrency,
    decimals,
    fromValue,
    setFromValue,
    isEmpty,
  };
});
