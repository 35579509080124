import { defineStore } from 'pinia';

export const useCurrenciesStore = defineStore('currencies', () => {
    const activeFromCurrencyId = ref(0);
    const activeToCurrencyId = ref(0);
    const fromDirection = ref({});
    const toDirection = ref({});
    function setActiveFromCurrencyId(currencyId) {
        if (currencyId) {
            activeFromCurrencyId.value = currencyId;
        } else {
            activeFromCurrencyId.value = 0;
        }
    }

    function setActiveToCurrencyId(currencyId) {
        if (currencyId) {
            activeToCurrencyId.value = currencyId;
        } else {
            activeToCurrencyId.value = 0;
        }
    }

    const currenciesIds = computed(() => ({
        from: activeFromCurrencyId.value,
        to: activeToCurrencyId.value,
    }));

    function setFromDirection(direction) {
        fromDirection.value = direction;
    }

    function setToDirection(direction) {
        toDirection.value = direction;
    }

    return {
        currenciesIds,
        setActiveFromCurrencyId,
        setActiveToCurrencyId,
        fromDirection,
        toDirection,
        setFromDirection,
        setToDirection,
    };
});
